<template>

	<div class="cont" id="p27">
		<div class="c01">
			<div class="p-header">
				<div class="page-title">{{ program.name }}</div>
				<div class="years">
					<div class="btn-prev"
						:class="{ active: isActive.prev }"
						@click="prev"><i class="xi-angle-left"></i></div>
					<div class="year-now">{{ search.inquiryMonth.substring(0, 4) }}년 {{ search.inquiryMonth.substring(4, 6)}}월</div>
					<div class="btn-next"
						:class="{ active: isActive.next }"
						@click="next"><i class="xi-angle-right"></i></div>
				</div>
				<div class="btn-excel"
					v-if="excel_items.length > 0"
				><vue-excel-xlsx
					:data="excel_items"
					:columns="columns"
					:filename="program.name"
					:sheetname="program.name"
				>
				엑셀 다운로드
				</vue-excel-xlsx></div>
				
				<div class="btn-excel"
					v-if="!excel_items.length"
				>
				엑셀 다운로드
				</div>
			</div>
			<div class="month-fee">
				<span class="p1">{{ search.inquiryMonth.substring(4, 6)}}월 대리점 수수료</span>
				<span class="amount">{{ summary.sumOfMonthlyFees | makeComma }}원</span>
			</div>
		</div>
		
		<div class="c02">
			<div class="table type12">
				<div class="item-header">
					<div class="item">
						<div class="td">날짜</div>
						<div class="td">하위 대리점</div>
						<div class="td">아이디</div>
						<div class="td">총 결제금액</div>
						<div class="td">총 결제건수</div>
						<div class="td">리셀러 수익</div>
						<div class="td">마진율</div>
						<div class="td">내 수익</div>
						<div class="td">상세</div>
					</div>
					<div class="item total">
						<div class="td">합계</div>
						<div class="td"></div>
						<div class="td"></div>
						<div class="td num">{{ summary.paymentAmount | makeComma }}원</div>
						<div class="td">{{ summary.paymentCount | makeComma }}건</div>
						<div class="td num">{{ summary.revenueSubAmount | makeComma }}원</div>
						<div class="td"></div>
						<div class="td num">{{ summary.merchantSettlementAmount | makeComma }}원</div>
						<div class="td"></div>
					</div>
				</div>
				<div class="item-list"
					v-for="(item, index) in items"
					:key="index"
				>
					<div class="item">
						<div class="td">{{ item.settlementDate }}</div>
						<div class="td">{{ item.businessName }}</div>
						<div class="td">{{ item.userId }}</div>
						<div class="td num">{{ item.paymentAmount | makeComma }}원</div>
						<div class="td">{{ item.paymentCount | makeComma }}건</div>
						<div class="td num">{{ item.revenueSubAmount | makeComma }}원</div>
						<div class="td num">{{ item.marginFeeRate | makeComma }}%</div>
						<div class="td num">{{ item.revenueAmount | makeComma }}원</div>
						<div class="td"><a @click="goDetail(item.childAgentSeq )">상세보기</a></div>
					</div>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	import { date } from '@/resources/date/date'
	import { columns } from '@/resources/columns/columns'

	export default {
		name: 'PaymentsHistory'
		,props: ['user']
		,components: {}
		,data: function(){
			return {
				program: {
					name: '하위 대리점 정산내역'
					,items: {
					}
				}
				,items: [
				]
				,columns: columns.Agent.settlement
				,summary: {
					inquiryYearMonth: ''
					,sumOfMonthlyFees: 0
				}
				,search: {
					inquiryMonth: '20201201'
				}
				,agentSeq: this.user.basic.agentSeq
				
				,isActive: {
					prev: true
					,next: false
				}
				,excel_items: {
				}
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/agent/' + this.agentSeq
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
					
					this.getSummary()
					
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,getSummary: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/agent/summary/' + this.agentSeq
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.summary = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
					
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,goDetail: async function(childAgentSeq){
				if(this.user.basic.level == 1){
					try{
						const result = await Axios({
							method: 'get'
							,url: '/settlements/agent/' + childAgentSeq
							,data: this.search
							,authorize: true
						})
						
						if(result.success){
							this.items = result.data.content.content
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message })
						}
						
						this.getSummary()
						
					}catch(E){
						this.$emit('setNotify', { type: 'error', message: E })
					}
				}else{
					this.$emit('setNotify', { type: 'error', message: '하위 영업점 정보가 없습니다' })
				}
			}
			,prev: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0,6) >= this.search.inquiryMonth.substring(0, 6)){
					this.isActive.next = true
				}
				this.search.inquiryMonth = date.getPrevMonth(this.search.inquiryMonth).fullDate
				await this.getData()
			}
			,next: async function(){
				let now = date.getToday()
				if(now.replaceAll('-', '').substring(0,6) <= this.search.inquiryMonth.substring(0, 6)){
					return false
				}
				this.search.inquiryMonth = date.getNextMonth(this.search.inquiryMonth).fullDate
				if(now.replaceAll('-', '').substring(0,6) <= this.search.inquiryMonth.substring(0, 6)){
					this.isActive.next = false
				}
				
				await this.getData()
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/settlements/agent/' + this.agentSeq + '/download'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: function(){
		}
		,watch: {
			user: {
				immediate: true
				,deep: true
				,handler: function(call){
					
//					console.log(call)
					if(call.basic.userSeq){
						this.getData()
					}
				}
			}
		}
	}
</script>























